@import '../../../../../variables.css';

::-ms-reveal {
    display: none;
}

.inputWrapper {
    margin-bottom: var(--ark-spacing-m);
    position: relative;
    width: 80%;
}

.policy {
    padding-bottom: var(--ark-spacing-xxl-7);
}

.forgotPassword,
.policy a {
    color: var(--ark-primary-blue-figma);
    line-height: var(--ark-18-font-size);
    text-align: center;
}
.policy a {
    font-size: var(--ark-18-font-size);
    text-decoration: none;
}
.forgotPassword {
    margin-bottom: var(--ark-spacing-m);
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-16-font-size);
}

.submitButton {
    max-width: 127px;
    border-radius: var(--ark-spacing-l);
    padding: 10px 30px;
    height: var(--ark-spacing-xxl-3);
}

.dividerBlock {
    margin: var(--ark-spacing-xxl-2) 0;
}

.socialButtons {
    display: flex;
    margin-bottom: 20px;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {

    .inputWrapper {
        width: 100%;
    }

    .inputWrapper:not(:last-child) {
        margin-bottom: 10px;
    }

    .dividerBlock {
        margin: var(--ark-spacing-m) 0;
    }

    .socialButtons {
        flex-direction: column;
        margin-bottom: var(--ark-spacing-xxl);
    }

    .socialButtons iframe:not(:last-child) {
        margin-bottom: 10px;
    }

    .tabContent {
        padding: 0 var(--ark-spacing-s);
    }
}
