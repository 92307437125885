@import '../../../../../../variables.css';

.container {
    width: 100%;
    height: 240px;
    display: grid;
    position: relative;
    border: double 4px transparent;
    box-shadow: 0 2px 40px rgba(214, 170, 63, 0.7);
    border-radius: 10px;
    background-image: linear-gradient(#0b103d, #0b103d),
        linear-gradient(314.74deg, #c28c2b 12.84%, #e6c14e 48.8%, #c28c2b 84.98%);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.restart .container {
    background-image: none;
    border: 4px solid var(--ark-primary-asbestos-figma);
    box-shadow: none;
    background-color: var(--ark-primary-royal-blue-figma);
}

.containerBg {
    background-image: url(/illustrations/profile-subscription/bg-avatar-card.png);
    position: absolute;
    width: 100px;
    height: 100%;
    background-repeat: no-repeat;
}

.restart .containerBg {
    display: none;
}

.avatarBlock {
    grid-column: 1/2;
    grid-row: 1/3;
    display: flex;
    align-items: center;
    left: 15px;
    position: relative;
}

.logoImg {
    width: 169px;
    height: 81px;
    grid-column: 2/3;
    grid-row: 1/2;
    margin-left: auto;
    margin-right: 36px;
    margin-top: 18px;
}

.restart .logoImg {
    width: 136px;
    height: 65px;
    margin-bottom: 12px;
}

.bottomText {
    font-weight: var(--ark-font-weight-regular);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    color: var(--ark-primary-white-figma);
    grid-column: 2/3;
    grid-row: 2/3;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-right: 36px;
    padding-bottom: 36px;
}

.bottomText .date {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-24-line-height);
}

.renew .bottomText .date,
.restart .bottomText .date {
    font-size: var(--ark-20-font-size);
}

.btnSubscribe {
    padding: 10px 20px;
    width: fit-content;
}

.link {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-22-line-height);
    color: var(--ark-main-cyan-figma);
    text-decoration: underline;
}

.link:hover {
    cursor: pointer;
}

.linkBillingButton {
    text-decoration: inherit;
    color: inherit;
}

.bottomText p {
    text-align: right;
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
}

.bottomBlock {
    margin-top: 8px;
}

/* 599 */
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .containerExtended {
        height: 100%;
    }

    .container {
        height: 132px;
    }

    .logoImg,
    .restart .logoImg {
        width: 103px;
        height: 50px;
        margin-right: 16px;
        margin-top: 12px;
    }

    .bottomText {
        font-weight: var(--ark-font-weight-regular);
        font-size: var(--ark-14-font-size);
        line-height: var(--ark-17-line-height);
        padding-right: 16px;
        padding-bottom: 16px;
    }

    .renew .bottomText .date, .restart .bottomText .date, .bottomText .date {
        font-weight: var(--ark-font-weight-bold);
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-17-line-height);
    }

    .avatarBlock {
        left: 12px;
        padding-right: 0;
    }

    .containerBg {
        width: 48px;
        background-size: cover;
    }

    .bottomBlock {
        display: none;
    }

    .bottomText p {
        text-align: right;
        font-size: var(--ark-14-font-size);
        line-height: var(--ark-17-line-height);
    }
}
