@import '../../../../variables.css';

.Root {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.Row {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin: 0;
}

.__RowLogo {
    min-height: 5rem;
}

.__RowSearch {
    padding: 1.5rem 0;
    margin-top: 1.25rem;
    margin-bottom: 2rem;
}

.__RowButtons {
    margin-bottom: 2rem;
}

.__RowCategoriesTitle {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 1rem;
    flex-wrap: nowrap;
}

.__RowCategoriesTitle_Left {
    transform: translate(0, -0.5rem);
}

.__RowCategoriesTitle_Left,
.__RowCategoriesTitle_Left p {
    display: inline;
    white-space: nowrap;
}

.__RowCategoriesTitle_Left svg {
    transform: translate(0, 0.5rem);
}

.__RowCategoriesTitle a,
.__RowCategoriesTitle a:visited,
.__RowCategoriesTitle a:active,
.__RowCategoriesTitle a:hover {
    color: var(--ark-primary-blue-figma-font) !important;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    padding-bottom: 0.25rem;
}

.ArkLogo {
    width: 57%;
    max-height: 5rem;
    cursor: initial;
    pointer-events: none;
    filter: grayscale(1) contrast(1.5);
    transform: translate(-33%, -25%);
}

.BackIcon {
    background-color: transparent;
    box-shadow: none;
    border: none;
    width: fit-content;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--ark-primary-black-figma);
    position: relative;
    margin: 0 auto 0 0;
    font-size: 0.75rem;
    font-weight: 700;
}

.BackIcon_icon {
    transform: rotate(-180deg);
}

.BackIcon_caption {
}

.ButtonsBlock,
.__RowCategories {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
}

.__RowCategories {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
}

.Button.Button.Button {
    width: 100%;
    max-width: calc(100vw - 1rem * 3);
    height: 3.75rem;
    max-height: 3.75rem;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #efefef;
    padding: 1.25rem 0.65rem;
    border-radius: 0.75rem;
    position: relative;
}

.Button.Button.Button svg {
    width: 2rem;
}

.__RowCategoriesTitle p,
.Button.Button.Button p {
    text-align: left;
    vertical-align: middle;
    font-size: 1.25rem;
    padding-left: 0.5rem;
    transform: translate(0, -0.05rem);
    white-space: nowrap;
    font-weight: 700;
}

.Button.Button.Button p {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.225rem;
}

.__RowCategoriesTitle p {
    transform: none;
}

.__RowCategories .Button a,
.__RowCategories .Button a > div,
.__RowCategories .Button a > div [class*='CategoryCard-categoryCardBack'],
.__RowCategories.__RowCategories.__RowCategories [class*='LazyImage-progressiveContainer'] {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 0.75rem;
    min-height: initial;
    max-height: 100%;
    transform: none !important;
    padding: 0;
}

.__RowCategories [class*='LazyImage-progressiveContainer']:before {
    display: none;
}

.__RowCategories [class*='CategoryCard-categoryCardCaption'],
.__RowCategories [class*='LazyImage-progressiveContainer'] picture,
.__RowCategories [class*='LazyImage-progressiveContainer'] img {
    position: absolute;
    left: 0.65rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
    max-height: 1.5rem;
}

.__RowCategories [class*='CategoryCard-categoryCardCaption'] {
    left: calc(0.65rem + 1.5rem + 0.65rem);
    width: auto;
    color: #f9f9f9;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;
    white-space: initial;
}

.ButtonSignIn [class*='AvatarGemsCounter-splitStyle'] {
    flex-direction: row-reverse;
    align-items: center;
    transform: scale(0.5) translate(-40%, 0);
}

.ButtonSignIn [class*='AvatarGemsCounter-avatarWithGemsAdj'] {
    margin-left: 0;
    margin-right: calc(0.25rem * 2);
}

.ButtonSignIn [data-element-description='nav-user-profile-link'] {
}

.AddGamePwaTutorial {
    position: relative;
    left: -1.5rem;
    width: 100vw;
    height: auto;
    min-height: calc(100% + 1.5rem);
    padding: 0 1.5rem calc(1.5rem + 4rem) 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.Root:has(.AddGamePwaTutorial),
.AddGamePwaTutorial {
    background-color: #3d56d7;
}
.AddGamePwaTutorial,
.AddGamePwaTutorial * {
    color: var(--ark-primary-white-figma);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.AddGamePwaTutorial [class*='DisplayAd-container-'] {
    position: relative;
    height: auto !important;
    max-height: 100px;
    margin: 0 auto;
}

.AddGamePwaTutorial [class*='DisplayAd-container-'] > display-ad-component {
    height: auto !important;
}

.AddGamePwaTutorialImageBlock,
.AddGamePwaTutorialImageBlock img {
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
}

.AddGamePwaTutorialImageBlock img {
    display: block;
}

.AddGamePwaTutorialRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    margin: 1.25rem 0;
    padding: 0;
    border: 0;
}

.AddGamePwaTutorialTitle {
    font-weight: var(--ark-font-weight-bold);
    align-items: center;
    margin-top: 1.5rem;
    padding-bottom: 1.25rem;
    border-bottom: 0.0625rem solid #efefef;
}
.AddGamePwaTutorialTitleImgBlock {
    width: auto;
    height: auto;
    padding: 1rem 1rem 1rem 0;
}

.AddGamePwaTutorialTitleImg.AddGamePwaTutorialTitleImg {
    width: 2.75rem;
    min-width: 2.75rem;
    height: 2.75rem;
    min-height: 2.75rem;
}

.AddGamePwaTutorialSteps {
    flex-direction: column;
}

.AddGamePwaTutorialStep {
    flex-direction: column;
    margin: 0 0 1.25rem 0;
}

.AddGamePwaTutorialStep:last-of-type {
    padding-bottom: 1.5rem;
}

.AddGamePwaTutorialStepText {
    margin-bottom: 0.5rem;
}

.AddGamePwaTutorialStepImgBlock {
    display: contents;
}

.AddGamePwaTutorialStepImg {
    width: 100%;
    height: auto;
}
