@import '../../../variables.css';

.validationBlock {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: -20px;
    left: 0;
    width: calc(100% + 100px); /* 100px added for additional space when input is small */
}

.validationBlock svg {
    min-width: 20px;
    min-height: 20px;
}

.validationBlock span {
    color: var(--ark-primary-red);
    font-size: var(--ark-14-font-size);
    padding-left: 4px;
}
