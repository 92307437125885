@import '../../../../../variables.css';

.tabContent {
    color: var(--ark-neutral-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 3rem 0 5rem 0;
}

.tabContent a {
    text-decoration: none;
}

.header {
    font-size: var(--ark-28-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-light);
    margin-bottom: 3rem;
}

.subHeader {
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-32-line-height);
    font-weight: var(--ark-font-weight-regular);
    margin-bottom: 1.875rem;
}

.subHeader p {
    text-align: center;
    font-weight: var(--ark-font-weight-bold);
}

.inputWrapper {
    margin-bottom: 1.25rem;
    position: relative;
}

.input {
    width: 20rem;
    height: 3rem;
}

.forgotPassword,
.policy a {
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-primary-blue-figma);
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-18-font-size);
    text-align: center;
}

.forgotPassword {
    margin-bottom: 3rem;
}

.submitButton {
    border-radius: 1.5rem;
    padding: 10px 30px;
    height: 3rem;
}

.textWithLink {
    margin-top: 30px;
}

.dividerBlock {
    margin: 2.5rem 0;
}

.socialButtons {
    display: flex;
    margin-bottom: 3.75rem;
}

.validation {
    position: relative;
    bottom: 0;
}

.repeatIcon {
    margin-left: 0.75rem;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .header {
        margin-bottom: 2rem;
    }
    .inputWrapper {
        margin-bottom: 0;
    }
}
