@import '../../../variables.css';

.tooltipContainer {
    position: relative;
    top: -1px;
}

.infoMessage {
    background-color: var(--ark-neutral-dark);
    border-radius: 1rem;
    display: flex;
    font-size: 0.875rem;
    font-weight: var(--ark-font-weight-bold);
    line-height: 1.29;
    color: var(--ark-neutral-white);
    justify-content: center;
    align-items: center;
    padding: 1rem;
    z-index: 1;
}

.tooltipMessageWrapper {
    display: flex;
    align-items: flex-end;
    position: absolute;
    width: 341px;
    height: 86px;
    min-height: 56px;
    top: -86px;
    z-index: 100;
    transform: scale(0);
}

.tooltipMessageWrapper.infoMessageVisible {
    transform: scale(1);
}

.hiddenFromScreenReader {
    display: none;
}

.screenReaderOnly {
    height: 1px;
    width: 1px;
    opacity: 0;
    pointer-events: none;
}

.infoMessage.attachment {
    right: -18px;
    top: -85px;
}

.infoMessage.attachment::before {
    left: 217px;
    border-top: 8px solid var(--ark-primary-blue-figma);
}

.infoButtonWrapper {
    height: 34px;
    width: 35px;
}

.infoButton {
    height: 34px;
    width: 35px;
}

.supportForm {
    right: -35px;
    top: 23px;
}

.storybook {
    right: auto;
}

.attachmentMessage {
    right: -32px;
}

.supportFormMessage {
    right: -35px;
}

.attachIcon {
    background-image: url(/icons/form/attach.svg);
    height: 34px;
    width: 34px;
    right: 25px;
}

.attachIconDisabled {
    background-image: url(/icons/form/attach.svg);
    height: 34px;
    width: 34px;
    right: 25px;
    opacity: 0.5;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .infoMessage::before {
        display: none;
    }

    .infoMessage.attachment {
        right: -14px;
        top: -100px;
        left: initial;
    }

    .supportForm {
        right: initial;
        left: -42px;
    }

    .supportFormMessage {
        right: inherit;
        left: -42px;
    }
}

@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .tooltipMessageWrapper {
        max-width: 50vw;
    }
}
