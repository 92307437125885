@import '../../../variables.css';

.container {
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: center;
    padding: var(--ark-spacing-xxl-3) var(--ark-spacing-xxl-8) var(--ark-spacing-xxl-8);
    background-color: var(--ark-advantage-blue-figma);
    background-image: url(/images/gems/lights-left.png), url(/images/gems/lights-right.png), url(/images/gems/top-right-bg2.png);
    background-position: 0px 0px, 100% 0px, 100% 0px;
    background-repeat: no-repeat, no-repeat, no-repeat;
}

.container>* {
    width: 100%;
    max-width: 33rem;
    min-width: fit-content;
}

@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .container {
        gap: var(--ark-spacing-s);
        padding: var(--ark-spacing-s) var(--ark-spacing-s) var(--ark-spacing-xxl-7);
    }
}