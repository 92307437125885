@import "../../../../../../variables.css";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    background-color: var(--ark-primary-royal-blue-figma-70);
    gap: 16px;
    border-radius: 10px;
    color: var(--ark-secondary-solid-lightgrey-figma);
    height: 100%;
    justify-content: center;
}

.textBlock p {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-28-line-height);
    text-align: center;
}

.textBlock span {
    font-weight: var(--ark-font-weight-bold);
}

.plan {
    display: inline-block;
}

.plan::first-letter {
    text-transform: uppercase;
}

.btnSubscribe{
    padding: 10px 20px;
    width: fit-content;
}

/* 599 */
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .container {
        padding: 16px;
    }

    .textBlock p {
        font-size: var(--ark-14-font-size);
        line-height: var(--ark-18-line-height);
        text-align: center;
    }
}