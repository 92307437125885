@import '../../../../variables.css';

.container {
    padding-top: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
    border-radius: 0.625rem;
}

#fc_frame {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin-bottom: -20px;
}

.chatBotBtn, .whatsAppBtn {
    width: 100%;
    padding: 0 2rem;
    margin-bottom: 1.5rem;
}

.chatBotBtn {
    background-color: var(--ark-primary-blue-figma);
}

.chatBotBtn:hover {
    background-color: var(--ark-primary-royal-blue-figma);
}

.whatsAppBtn {
    background-color: var(--ark-primary-green);
}

.whatsAppBtn svg {
    margin-right: 10px;
}

.h2 {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-34-line-height);
    color: var(--ark-black-color);
    margin-bottom: 1.5rem;
}

.ul li {
    list-style: disc;
}

.question {
    border: none;
    display: inline-block;
    background-color: inherit;
    font-weight: var(--ark-font-weight-regular);
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-18-line-height);
    color: var(--ark-black-color);
    cursor: pointer;
    margin-bottom: var(--ark-spacing-s);
    text-align: left;
    text-decoration: underline;
}

.supportCards {
    display: grid;
    grid-template-columns: 1fr 1.8rem auto;
}

.supportCards li:nth-child(1) {
    grid-column: 1/2;
}

.supportCards li:nth-child(2) {
    grid-column: 3/4;
}

.dropdownContainer {
    box-shadow: 0 2px 4px var(--ark-black-15-color);
}

.dropdownButton {
    height: 3rem;
    background-color: var(--ark-neutral-white);
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    color: var(--ark-gray-dark-color);
}

.dropdownCaption {
    font-weight: var(--ark-font-weight-regular) !important;
    font-size: var(--ark-20-font-size) !important;
    line-height: var(--ark-32-line-height) !important;
    color: inherit !important;
}

.dropdownIcon {
    color: inherit !important;
}

.questionMobile {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-weight: var(--ark-font-weight-bold);
    font-size: 1.25rem !important;
    line-height: 3.5rem !important;
    color: var(--ark-tertiary-color) !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: color 0.166s linear;
    width: 100%;
    text-align: left;
}

.questionMobile:hover {
    color: var(--ark-primary-blue-figma) !important;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .whatsAppText {
        margin-top: 1.5rem;
        text-align: center;
    }

    .whatsAppBtn, .chatBotBtn {
        width: 275px;
        margin: 1.5rem auto;
    }

    .extendMediaQuery .container {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .extendMediaQuery .mobileInnerContainer {
        padding-left: 1.25rem;
    }

    .extendMediaQuery .supportCards {
        grid-template-columns: 1fr 5rem 1fr;
    }

    .extendMediaQuery .supportCards li:nth-child(1) {
        display: flex;
        justify-content: flex-end;
    }

    .container {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .mobileInnerContainer {
        padding-left: 1.25rem;
    }

    .supportCards {
        grid-template-columns: 1fr 5rem 1fr;
    }

    .supportCards li:nth-child(1) {
        display: flex;
        justify-content: flex-end;
    }

    .supportFormHeight {
        height: calc(100% + 14rem);
    }
}

@media (max-width: var(--ark-new-med-small-mobile-above-361)) {
    .supportFormHeight {
        height: calc(100% + 15rem);
    }
}

@media (max-width: var(--ark-extra-small-figma)) {
    .supportFormHeight {
        height: calc(100% + 16rem);
    }
}
